import React from "react";
import Section from "../../components/Section";
import { Column } from "./styles";
import Typography from "../../components/Typography";

const Security = () => {
  return (
    <Section shrinkTop>
      <Column>
        <Typography variant="h1">Security</Typography>
        <p>
          Security is foundational to everything we do at Outline. You&#39;ve
          entrusted us with your data, and we treat it as if it&#39;s our own.
          We have created a security model to ensure that your data is protected
          by the very best practices and tools in the industry.
        </p>
        <h2>Architecture &amp; Procedures</h2>
        <ul>
          <li>
            All of our infrastructure is hosted in AWS within a virtual private
            network. Our servers cannot be accessed via the public internet,
            except for those needed to proxy requests.
          </li>
          <li>
            All activity within AWS is monitored and logged using{" "}
            <a
              href="https://aws.amazon.com/cloudtrail/"
              target="_blank"
              rel="noopener noreferrer"
            >
              AWS Cloudtrail
            </a>
            .
          </li>
          <li>
            We utilize security tools like{" "}
            <a
              href="https://aws.amazon.com/waf"
              target="_blank"
              rel="noopener noreferrer"
            >
              AWS WAF
            </a>
            ,{" "}
            <a
              href="https://aws.amazon.com/shield/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shield
            </a>
            , and{" "}
            <a
              href="https://aws.amazon.com/guardduty/"
              target="_blank"
              rel="noopener noreferrer"
            >
              GuardDuty
            </a>
            .
          </li>
          <li>
            Prior to every deploy, our codebase is analyzed for security issues
            using{" "}
            <a
              href="https://www.nccgroup.com/us"
              target="_blank"
              rel="noopener noreferrer"
            >
              NCC Group's
            </a>{" "}
            <a
              href="https://github.com/nccgroup/sobelow"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sobelow
            </a>
            .
          </li>
          <li>
            Only people who need access, get access. No one is permitted to view
            your data without your permission.
          </li>
          <li>
            We enable encryption of all data both at rest and in transit over
            public networks.
          </li>
          <li>
            We create database backups every 24 hours and backups are retained
            for 30 days.
          </li>
          <li>
            We integrate to other services (like Salesforce) using OAuth2, and
            we never store your username and password for those services.
          </li>
          <li>Outline login passwords are hashed and salted using Argon2.</li>
          <li>
            We&#39;re in the process of obtaining our SOC 2 certification.
          </li>
        </ul>
        <h2>Privacy</h2>
        <p>
          We are in full support of data privacy regulations, such as GDPR, and
          we are committed to your privacy beyond compliance. We feel strongly
          that your data is your data, and it will never be used for mining or
          advertising purposes.
        </p>
        <h3>Data Management</h3>
        <p>
          If you would like to exercise your privacy rights, you can send your
          request to{" "}
          <a href="mailto:security@heyoutline.com">security@heyoutline.com</a>{" "}
          at any time.
        </p>
        <h4>Export</h4>
        <p>
          We will provide you with a folder containing all of your data from
          Outline organized by data type. Notes will be sent in a markdown
          format.
        </p>
        <h4>Deletion</h4>
        <p>
          Deleting your account will automatically deactivate it. After 30 days,
          all backups will be removed from our servers, and your data will no
          longer be recoverable.
        </p>
        <h4>Correction</h4>
        <p>
          We provide in-app functionality to modify your personal information
          such as first name, last name, and email.
        </p>
        <h3>Sub-processors</h3>
        <ul>
          <li>
            <a
              href="https://aws.amazon.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Amazon Web Services
            </a>{" "}
            (USA)
          </li>
          <li>
            <a
              href="https://sentry.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sentry
            </a>{" "}
            (USA)
          </li>
          <li>
            <a
              href="https://stripe.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stripe
            </a>{" "}
            (USA)
          </li>
        </ul>
        <h3>Consent</h3>
        <p>
          We use cookies to securely identify and authenticate your account.
        </p>
        <p>
          Data collection practices are presented and granted consent upon sign
          up. Consent can be withdrawn at any time by submitting a deletion
          request.
        </p>
        <h2>Contact</h2>
        <p>
          If you have any questions or concerns, please contact{" "}
          <a href="mailto:security@heyoutline.com">security@heyoutline.com</a>.
        </p>
      </Column>
    </Section>
  );
};

export default Security;
